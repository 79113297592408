@use '@angular/material' as mat;
@import "../node_modules/angular-notifier/styles.scss";
@import '@angular/material/theming';
@import './theme.scss';

/* Importing Bootstrap SCSS file. */
@import "../node_modules/bootstrap/scss/bootstrap";

@include mat.core();

$small-screen: 480px;
$medium-screen: 768px;
$large-screen: 1024px;

html,
body {
  height: 100%;
  font-size: 14px;
  @media (min-width: 1400px) {
    font-size: 16px;
  }
}

body {
  font-family: Roboto,
    "Helvetica Neue",
    sans-serif;
}

body .ui-toolbar {
  border: none !important;
  padding: 0% 0% 2% !important;
}

body .ui-table-scrollable-view .ui-table-scrollable-header {
  overflow: unset !important;
}

body .title-screen-ui {
  font: Roboto, "Helvetica Neue", sans-serif !important;
  font-style: bold !important;
  margin-top: 1.5% !important;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  color: #52606F;
  font-weight: bold !important;
}

body .subtitle-screen-ui {
  @extend .title-screen-ui;
  font-size: 17.5px;
}

/* Tables */

main .ui-datatable-tablewrapper {
  max-height: 65vh;
  overflow-y: auto;
  text-align: center;
}

main .ui-datatable-thead>tr>th {
  background-color: #6e6e6e !important;
  color: #fff;
}

main .ui-datatable-thead>tr>th:not(.col-button):hover {
  background-color: #5daace !important;
  color: #fff !important;
}

main .ui-datatable-thead>tr>th span.ui-column-title {
  color: #fff;
  display: inline-block;
  overflow: hidden;
  max-width: 65%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

main .ui-datatable-thead>tr>th span.ui-sortable-column-icon {
  float: right;
}

main .ui-datatable-thead>tr>th span.ui-sortable-column-icon:hover {
  cursor: pointer;
}

main .ui-datatable-thead>tr>th:hover {
  cursor: move;
}

main .ui-datatable-thead>tr>th input {
  background-color: #222222 !important;
  border-color: #222222 !important;
  border-radius: 20px;
  color: #fff !important;
  padding-left: 10px;
}

main .ui-datatable-thead>tr>th label.ui-inputtext,
main .ui-datatable-thead>tr>th .ui-column-filter {
  background-color: #222222 !important;
  border-color: #222222 !important;
  border-radius: 20px;
  color: #fff !important;
  padding-left: 5px;
}

main .ui-datatable-thead>tr>th label.ui-inputtext {
  position: relative;
  top: -1px;
  height: 25px;
}

main .ui-datatable-thead>tr>th.ui-dropdown-trigger {
  background: transparent !important;
  border: 0;
  color: #757575;
}

main .ui-datatable-thead>tr>th span.fa-close {
  cursor: pointer;
}

main .ui-datatable tbody.ui-datatable-hoverable-rows>tr.ui-widget-content:not(.ui-state-highlight):hover {
  background-color: #d6d6d6 !important;
}

main .ui-datatable tbody>tr.ui-widget-content.ui-state-highlight {
  background-color: #5daace !important;
  color: #fff !important;
}

main .ui-datatable-scrollable-body {
  overflow-y: scroll;
}

main .ui-paginator-bottom.ui-paginator.ui-widget.ui-widget-header {
  background: transparent !important;
  border: 0px !important;
  padding-top: 25px !important;
}

main .ui-paginator .ui-paginator-page.ui-state-active {
  background: #5daace;
  border-color: #5daace;
  color: #fff;
}

/* Table toolbar */

main .ui-grid-toolbar {
  background: transparent !important;
  border: 0 !important;
  padding: 10px !important;
}

/* Buttons */

main .ui-button .ui-button-text {
  position: initial !important;
}

main .ui-grid-toolbar .p-button.p-button-icon-only {
  height: 2.5rem !important;
  width: 2.5rem !important;
}

main .ui-datatable thead .ui-button.ui-button-icon-only,
main .ui-datatable tbody .ui-button.ui-button-icon-only {
  background-color: transparent !important;
  border: transparent !important;
  color: #222222 !important;
  height: 25px !important;
}

main .ui-datatable tbody .ui-button.ui-button-icon-only {
  width: 25px;
}

main .ui-button.ui-button-icon-only.ui-button-primary,
main .ui-datatable thead .ui-button-icon-only.ui-button-primary:hover,
main .ui-datatable tbody .ui-button-icon-only.ui-button-primary:hover {
  background-color: #2399E5 !important;
  border: 1px solid #2399E5 !important;
  color: #fff !important;
}

main .ui-button.ui-button-icon-only.ui-button-secondary,
main .ui-datatable thead .ui-button-icon-only.ui-button-secondary:hover,
main .ui-datatable tbody .ui-button-icon-only.ui-button-secondary:hover {
  background-color: #31B0D5 !important;
  border: 1px solid #31B0D5 !important;
  color: #fff !important;
}

main .ui-button.ui-button-icon-only.ui-button-success,
main .ui-datatable thead .ui-button-icon-only.ui-button-success:hover,
main .ui-datatable tbody .ui-button-icon-only.ui-button-success:hover {
  background-color: #4CAE4C !important;
  border: 1px solid #4CAE4C !important;
  color: #fff !important;
}

main .ui-button.ui-button-icon-only.ui-button-warning,
main .ui-datatable thead .ui-button-icon-only.ui-button-warning:hover,
main .ui-datatable tbody .ui-button-icon-only.ui-button-warning:hover {
  background-color: #F0AD4E !important;
  border: 1px solid #F0AD4E !important;
  color: #fff !important;
}

main .ui-button.ui-button-icon-only.ui-button-danger,
main .ui-datatable thead .ui-button-icon-only.ui-button-danger:hover,
main .ui-datatable tbody .ui-button-icon-only.ui-button-danger:hover {
  background-color: #D9534F !important;
  border: 1px solid #D9534F !important;
  color: #fff !important;
}

main .ui-button.ui-button-icon-only:hover {
  transform: scale(1.1, 1.1);
}

/* Forms */

main form.formContainer {
  padding-top: 10px;
  height: 100%;
}

main form.formContainer .pInputSwitchContainer {
  padding-bottom: .1em;
}

main .ui-fieldset,
.ui-fieldset .ui-fieldset-legend {
  padding: 0.1em 1em !important;
}

footer.btn-group {
  padding-top: 20px;
}

footer.btn-group .ui-button {
  height: 25px;
}

/* Grid responsive overflow-y */

@media (max-width: 767px) {
  main .ui-datatable-tablewrapper {
    max-height: 65vh;
    overflow-y: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  main .ui-datatable-tablewrapper {
    max-height: 65vh;
    overflow-y: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  main .ui-datatable-tablewrapper {
    max-height: 50vh;
    overflow-y: auto;
  }
}

@media (min-width: 1200px) {
  main .ui-datatable-tablewrapper {
    max-height: 50vh;
    overflow-y: auto;
  }
}

@media (min-width: 1200px) and (min-height: 768px) {
  main .ui-datatable-tablewrapper {
    max-height: 60vh;
    overflow-y: auto;
  }
}

/* Form responsive overflow-y */

@media (max-width: 767px) {
  main form.formContainer {
    min-height: 320px;
    overflow-y: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  main form.formContainer {
    max-height: 80vh;
    overflow-y: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  main form.formContainer {
    max-height: 65vh;
    overflow-y: auto;
  }
}

@media (min-width: 1200px) {
  main form.formContainer {
    max-height: 70vh;
    overflow-y: auto;
  }
}

@media (min-width: 1200px) and (min-height: 768px) {
  main form.formContainer {
    max-height: 75vh;
    overflow-y: auto;
  }
}


/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-border-radius: 2px;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background: #52606F;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #52606F;
}

/* Task select screen*/
.ui-task-select-title {
  font-weight: bold;
  font-size: 20px;
  padding-top: 3%;
}

.ui-task-option-button {
  width: 80vw;
  margin-bottom: 3% !important;
  background-color: #00263a;
  color: #FFFFFF;
}

.searchbox-input {
  font-size: 14px !important;
}

.mat-option-text {
  font-size: 12px !important;
}

.ui-accordion-settings {
  font: Roboto, "Helvetica Neue", sans-serif !important;
  margin-bottom: 5% !important;
  background-color: #00263a;
}

.ui-button-settings {
  margin-top: 1% !important;
}

.button-ui-receipt {
  width: 55% !important;
}

.ui-button-back {
  margin-bottom: -30px;
  margin-top: 10px;
}

.ui-rejection-button {
  width: 85vw;
  margin-bottom: 3% !important;
  background-color: #00263a;
  color: #FFFFFF;
}

/**********************************/
/*Styles about override in primeng angular-material*/

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary>.p-button,
.p-splitbutton.p-button-secondary>.p-button {
  background: #00263A !important;
}

.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: #00263A !important;
}

.p-overlaypanel .p-overlaypanel-close {
  background: #00263A;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: white;
  background: #00263a;
  opacity: 0.7;
}

/*input-switch*/
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #00263A !important;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: #00263A !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #00263A !important;
}

/*grid-paginator*/
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #00263A40;
  color: #00263a;
}

/*Checkbox*/

.p-checkbox .p-checkbox-box.p-highlight, .p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box.p-highlight:hover {
  border-color: #00263A !important;
  background: #00263A !important;
}

/*List box*/

.p-listbox .p-listbox-list .p-listbox-item.p-highlight, .p-listbox .p-listbox-list
  .p-listbox-item:focus.p-highlight {
  border-color: #00263A;
  background-color: #EDF2F9;
  color:#00263AB3
}

#selection-mode {
  flex: none !important;
  width: 3em;
}

#table-td-selection {
  flex: none !important;
  width: 3em;
}

.p-datatable-scrollable .p-datatable-thead>tr>th,
.p-datatable-scrollable .p-datatable-tbody>tr>td,
.p-datatable-scrollable .p-datatable-tfoot>tr>td {
  justify-content: center !important;
  min-width: 8rem;
}

.filter-properties {
  margin-top: 20px;
}

html, body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

@include mobile {
  .p-autocomplete .p-autocomplete-panel {
    max-height: 7rem !important;
    font-size: 0.875rem;
  }
}

scf-user-panel {
  .user-password-icon {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
}

.btn-save-changes {
  color: #FFFF;
  display: inline !important;
  text-align: center !important;
  align-items: center !important;
  width: 80% !important;
  padding-top: 2% !important;
  margin-left: 10% !important;
  background-color: #4CAE4C !important;
  height: 35px !important;
}
.wrongQuantityScanned {
  color: #c62828 !important;
}

.ui-receipt-layout {
  @media (min-width: $small-screen) {
    margin-left: 1% !important
  }

  @media (min-width: $medium-screen) {
    margin-left: 1% !important
  }

  @media (min-width: $large-screen) {
    margin-left: 0.5% !important
  }
}

// Media definition to add margin in main content
@media screen and (max-width: $small-screen) {
  mat-sidenav-content {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
}

.ui-btn-block {
  width: fit-content !important;
  max-width: 60% !important;
}
